import React from "react";
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/Leaderboard/Leaderboard')
    )
  } else {
    return (
      import('@components/Leaderboard/LeaderboardMobile')
    )
  }
}

const Leaderboard = ReactDynamicImport({ loader })

const LeaderboardPage = () => <Leaderboard />

export default LeaderboardPage;
